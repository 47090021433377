import scrollMonitor from 'scrollmonitor'

const sectionBlocks = Array.from(document.querySelectorAll('.section-block'))

sectionBlocks.forEach(sectionBlock => {
  var elementWatcher = scrollMonitor.create(sectionBlock, -200)
  // This locks the position values so that when we check them later they are still the initial values
  elementWatcher.lock()
  elementWatcher.enterViewport(() => {
    sectionBlock.classList.add('-done')
  })
})

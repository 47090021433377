import FontFaceObserver from 'fontfaceobserver'

const htmlElement = document.querySelector('html')

const fonts = {
  'Open Sans': { weight: 400 },
  'Sure Solomon': { weight: 900 },
}

let observers = []

Object.keys(fonts).forEach(function(family) {
  var data = fonts[family]
  var obs = new FontFaceObserver(family, data)
  observers.push(obs.load())
})

const prepareCSSClass = string => {
  return '-wf-' + string.replace(' ', '-').toLowerCase() + '-loaded'
}

Promise.all(observers)
  .then(function(fonts) {
    fonts.forEach(function(font) {
      htmlElement.classList.add(prepareCSSClass(font.family))
      // console.log(font.family + ' ' + font.weight + ' ' + 'loaded')
    })
  })
  .then(function() {
    htmlElement.classList.add('-wf-loaded')
  })
  .catch(function(err) {
    console.warn('Some critical font are not available:', err)
  })

/*const openSans = new FontFaceObserver('Open Sans', {
  weight: 400,
})

const font = new FontFaceObserver('Open Sans', {
  weight: 400,
})

font.load().then(function() {
  htmlElement.classList.add('-open-sans-loaded')
}, function() {
  htmlElement.classList.add('-open-sans-failed')
})*/

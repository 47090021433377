;(function() {
  equalHeight(true)
})()

window.onresize = function() {
  equalHeight(true)
}

function equalHeight(resize) {
  let elements = document.getElementsByClassName('equalHeight'),
    allHeights = [],
    i = 0
  if (resize === true) {
    for (i = 0; i < elements.length; i++) {
      elements[i].style.height = 'auto'
    }
  }
  for (i = 0; i < elements.length; i++) {
    let elementHeight = elements[i].clientHeight
    allHeights.push(elementHeight)
  }
  for (i = 0; i < elements.length; i++) {
    elements[i].style.height = Math.max.apply(Math, allHeights) + 'px'
  }
}
